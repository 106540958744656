.abilities-home {
  width: 100%;
  display: grid;
  justify-content: center;
  background-color: #fff;
  overflow: hidden;
  margin: 64px 0;
  @media( min-width: 768px) {
    margin: 0;
  }
}

.abilities-home__container {
  display: grid;
}

.abilities-home__circles {
  width: 355px;
  justify-self: center;
  justify-content: end;
  display: grid;
  grid-template-rows: 236px 219px 280px;
  @media (min-width: 768px) {
    width: 100%;
    grid-template-rows: unset;
    grid-template-columns: 234px 234px 280px;
    justify-content: center;
  }
  @media (min-width: 1440px) {
    grid-template-columns: repeat(3, max-content);
    grid-column-gap: 50px;
    align-items: center;
  }
}

.abilities-home__circle {
  width: 280px;
  height: 280px;
  display: grid;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
  padding: 57px 0 0 14px;
  @media (min-width: 768px) {
    margin: 0;
  }
  @media (min-width: 1440px) {
    width: 400px;
    height: 400px;
    padding-top: 116px;
  }
}

.abilities-home__circle--content {
  width: 166px;
  display: grid;
  grid-row-gap: 5px;
  justify-content: center;
  align-content: flex-start;

  &--turquoise {
    grid-row-gap: 8px;
  }
  @media (min-width: 768px) {
    grid-row-gap: 17px;

    &--turquoise {
      grid-row-gap: 20px;
    }
  }
  @media (min-width: 1440px) {
    width: 192px;
    grid-row-gap: 22px;
  }
}

.abilities-home__circle--title {
  font-family: "Nexa Bold";
  font-size: 18px;
  line-height: 1.5;
  @media (min-width: 1440px) {
    font-size: 24px;
    line-height: 30px;
  }
}

.abilities-home__circle--text {
  font-family: "Nexa Light";
  font-size: 14px;
  line-height: 22px;
}

.abilities-home__circle--gray {
  margin-right: 75px;
  background-color: #515f6b;
  @media (min-width: 768px) {
    margin: 0;
  }
  @media (min-width: 1440px) {
    padding-left: 36px;
  }
}

.abilities-home__circle--red {
  margin-left:  75px;
  background-color: #f0525f;
  @media (min-width: 768px) {
    margin: 0;
  }
  @media (min-width: 1440px) {
    padding-left: 0px;
  }
}

.abilities-home__circle--turquoise {
  margin-right:  75px;
  background-color: #65cebd;
  color: #253746;
  @media (min-width: 768px) {
    margin: 0;
  }
  @media (min-width: 1440px) {
    padding-left: 27px;
  }
}
