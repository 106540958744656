.home {
  width: 100%;
  min-width: 375px;
  display: grid;
  @media (min-width: 768px) {
    overflow: hidden;
    margin-top: 64px;
    grid-row-gap: 64px;
  }
}

.home__button {
  width: max-content;
  justify-self: center;
  text-decoration: none;
  padding: 23px 48px;
  border-radius: 37px;
  font-family: "Nexa Bold";
  font-size: 14px;
  font-weight: bold;
  line-height: 1.5;
  color: #253746;
  display: block;
  background-color: #ffb81c;
  cursor: pointer;
  margin-top: 32px;
  @media (min-width: 768px) {
    margin: 0;
  }
}
