.vacancy-page {
    width: 100%;
    min-width: 375px;
    display: grid;
  
    @media (min-width: 768px) {
      margin-top: 64px;
      grid-row-gap: 64px;
      overflow: hidden;
    }
  }

  .vacancy-page-flex {
    display: flex;
  }
  
  .vacancy-page__button {
    text-decoration: none;
    width: max-content;
    height: max-content;
    padding: 9px 20px;
    border-radius: 37px;
    font-family: "Nexa Bold";
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    display: block;
    background-color: #253746;
  }
  
  .vacancy-page__title {
    font-family: "Nexa Regular";
    font-size: 36px;
    font-weight: normal;
    line-height: 55px;
    color: #253746;
  }  