.offer {
  width: 100%;
  display: grid;
  justify-content: center;
  background-color: #66cdbd;
  @media (min-width: 768px) {
    background-color: unset;
  }
}

.offer__container {
  padding-top: 51px;
  padding-bottom: 57px;
  display: grid;
  grid-row-gap: 20px;
  @media (min-width: 768px) {
    width: 768px;
    padding-left: 64px;
    padding-bottom: 61px;
    background-color: #66cdbd;
    background-image: url(../../../assets/images/Vacancies/Offer/offer768.webp);
    background-position: 592px;
    background-repeat: no-repeat;
    background-size: contain;
    @media (-webkit-min-device-pixel-ratio: 2) {
      background-image: url(../../../assets/images/Vacancies/Offer/offer768@2x.webp);
    }
    @media (-webkit-min-device-pixel-ratio: 3) {
      background-image: url(../../../assets/images/Vacancies/Offer/offer768@3x.webp);
    }
  }
  @media (min-width: 1440px) {
    width: 1392px;
    background-size: contain;
    background-image: url(../../../assets/images/Vacancies/Offer/offer.webp);
    @media (-webkit-min-device-pixel-ratio: 2) {
      background-image: url(../../../assets/images/Vacancies/Offer/offer@2x.webp);
    }
    @media (-webkit-min-device-pixel-ratio: 3) {
      background-image: url(../../../assets/images/Vacancies/Offer/offer@3x.webp);
    }
  }
}

.offer__title {
  font-family: "Nexa Regular";
  font-size: 36px;
  font-weight: normal;
  line-height: 55px;
  color: #253746;
}

.offer__list {
  margin-left: 18px;
  display: grid;
  grid-row-gap: 27px;
  @media (min-width: 768px) {
    margin-left: 0;
  }
}

.offer__list-item {
  display: grid;
  grid-template-columns: 15px 271px;
  grid-column-gap: 10px;
  font-family: "Nexa Regular";
  font-size: 18px;
  line-height: 1.5;
  color: #253746;
  @media (min-width: 768px) {
    grid-template-columns: 15px 464px;
  }
}

.offer__list-item__marker {
  margin-top: 6px;
}