.header {
  width: 100%;
  min-width: 375px;
  height: 83px;
  padding: 24px;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  justify-content: space-between;
  background-color: #253746;
  
  @media (min-width: 1440px) {
    grid-column-gap: 1163px;
    justify-content: center;
  }
}

.header__logo {
  width: 140px;
  display: block;
}

.header__language {
  margin-top: 3px;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-column-gap: 32px;
  font-family: 'Nexa Bold';
  font-size: 18px;
  line-height: 1.5;
  font-weight: bold;
}

.header__language-inactive {
  color: #d3d7da;
}

.header__language-active {
  color: #253746;
}