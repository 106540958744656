.slider {
  background-color: #f0f3f6;
  width: 100%;
  overflow-x: hidden;
}

.slider__container {
  width: 350px;
  margin: auto;
  padding-top: 68px;
  padding-bottom: 67px;
  @media (min-width: 768px) {
    width: 855px;
    padding-top: 67px;
  }
  @media (min-width: 1440px) {
    width: 1392px;
    padding-bottom: 60px;
  }
}

.slider__title {
  font-family: "Nexa Bold";
  font-size: 36px;
  line-height: 1.19;
  text-align: left;
  color: #253746;
  margin-bottom: 32px;
  margin-left: 19px;
  @media (min-width: 768px) {
    margin-left: 64px;
    margin-bottom: 65px;
    font-size: 36px;
  }
}

.slider__image {
  width: 175px;
  height: 72px;
  text-align: center;

  @media (min-width: 768px) {
    width: 214px;
    height: 90px;
  }

  @media (min-width: 1440px) {
    width: 232px;
    height: 90px;
  }
}

.slider__logo {
  width: 120px;
  height: 72px;
  object-fit: contain;

  @media (min-width: 768px) {
    width: 150px;
    height: 90px;
  }
}
