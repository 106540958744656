@font-face {
    font-family: "Nexa Bold";
    src: url("../public/fonts/NexaBold.woff2") format('woff2');
}
@font-face {
    font-family: "Nexa Light";
    src: url("../public/fonts/NexaLight.woff2") format("woff2");
}

@font-face {
  font-family: "Nexa Regular";
  src: url("../public/fonts/NexaRegular.woff2") format("woff2");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

