.the-way-we-work {
  width: 100%;
  display: grid;
  justify-content: center;
  background-color: #ffc649;
  @media (min-width: 768px) {
    background-color: unset;
  }
}

.the-way-we-work__container {
  width: 322px;
  padding-top: 67px;
  padding-bottom: 61px;
  display: grid;
  grid-row-gap: 23px;
  background-color: #ffc649;

  @media (min-width: 768px) {
    width: 768px;
    justify-content: center;
    grid-row-gap: 22px;
    padding-top: 68px;
    padding-left: 61px;
    padding-bottom: 124px;
    background-image: url(../../../assets/images/Home/the-way-we-work768.webp);
    background-position: left;
    background-repeat: no-repeat;
    background-size: contain;
    @media (-webkit-min-device-pixel-ratio: 2) {
      background-image: url(../../../assets/images/Home/the-way-we-work768@2x.webp);
    }
    @media (-webkit-min-device-pixel-ratio: 3) {
      background-image: url(../../../assets/images/Home/the-way-we-work768@3x.webp);
    }
  }
  @media (min-width: 1440px) {
    width: 1392px;
    padding-top: 67px;
    padding-bottom: 125px;
    padding-right: 180px;
    justify-content: end;
    background-size: 800px;
    background-image: url(../../../assets/images/Home/the-way-we-work.webp);

    @media (-webkit-min-device-pixel-ratio: 2) {
      background-image: url(../../../assets/images/Home/the-way-we-work@2x.webp);
    }
    @media (-webkit-min-device-pixel-ratio: 3) {
      background-image: url(../../../assets/images/Home/the-way-we-work@3x.webp);
    }
  }
}

.the-way-we-work__title {
  margin-left: 6px;
  font-family: "Nexa Bold";
  font-size: 36px;
  line-height: 1.19;
  color: #253746;
  letter-spacing: -0.4px;
  @media (min-width: 768px) {
    margin: 0;
  }
}

.the-way-we-work__list {
  margin-left: 6px;
  display: grid;
  grid-row-gap: 27px;
  @media (min-width: 768px) {
    margin-left: 0;
  }
}

.the-way-we-work__list-item {
  display: grid;
  grid-template-columns: 15px 300px;
  grid-column-gap: 10px;
  font-family: "Nexa Bold";
  font-size: 18px;
  line-height: 1.5;
  color: #253746;
  @media (min-width: 768px) {
    grid-template-columns: 15px 320px;
  }
}

.the-way-we-work__list-item__marker {
  margin-top: 7px;
}
