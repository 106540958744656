.experience {
  width: 100%;
  display: grid;
  justify-content: center;
  background-color: #ffc649;
  @media (min-width: 768px) {
    background-color: unset;
  }
}

.experience__container {
  width: 322px;
  padding-top: 55px;
  padding-bottom: 61px;
  display: grid;
  grid-row-gap: 25px;
  background-color: #ffc649;

  @media (min-width: 768px) {
    width: 768px;
    grid-row-gap: 22px;
    padding-top: 57px;
    padding-left: 64px;
    padding-bottom: 64px;
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
  }
  
  @media (min-width: 1440px) {
    width: 1392px;
    padding-top: 54px;
    padding-bottom: 73px;
    background-size: contain;
  }
}

.experience__text {
  margin-left: 6px;
  font-family: "Nexa Regular";
  font-size: 18px;
  line-height: 1.5;
  color: #253746;
  @media (min-width: 768px) {
    width: 429px;
    font-size: 24px;
    margin: 0;
  }
}

.experience__list {
  margin-left: 22px;
  display: grid;
  grid-row-gap: 27px;
  @media (min-width: 768px) {
    margin-left: 0;
  }
}

.experience__list-item {
  display: grid;
  grid-template-columns: 15px 263px;
  grid-column-gap: 10px;
  align-items: center;
  font-family: "Nexa Regular";
  font-size: 18px;
  line-height: 1.5;
  color: #253746;
  @media (min-width: 768px) {
    grid-template-columns: 15px 423px;
  }
}
