.alice-carousel__dots {
  width: 100%;
  max-width: 100vw;
  margin: 25px 0 -40px 0;
  transform: translateX(7px);
  @media (min-width: 768px) {
    margin: 28px 0 -40px 0;
  
  }
}

.alice-carousel__dots-item {
  width: 5px !important;
  height: 5px !important;
  background-color: #a8afb5 !important;

  &.__active {
    background-color: #253746 !important;
  }
  @media (min-width: 768px) {
    width: 10px !important;
    height: 10px !important;
  }
}
