.description {
  width: 100%;
  min-height: 560px;
  display: grid;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;

  &--image {
    height: 50%;
  }

  @media (min-width: 768px) {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100%;
  }
  @media (min-width: 1440px) {
    height: 560px;
    background-image: none;
  }
}

.description__container {
  max-width: 280px;
  margin-right: 32px;
  padding-top: 24px;
  padding-bottom: 24px;
  display: grid;
  grid-auto-rows: max-content;
  grid-row-gap: 51px;

  @media (max-width: 767px) {
    max-width: 100%;
    margin-right: 0;
  }

  @media (min-width: 768px) {
    max-width: unset;
    width: 768px;
    grid-template-columns: 89px 415px;
    grid-column-gap: 148px;
    grid-row-gap: 24px;
    padding-left: 64px;
    margin: 0;
    padding-bottom: 50px;
  }
  @media (min-width: 1440px) {
    width: 1392px;
    padding-left: 125px;
    padding-bottom: 24px;
    background-repeat: no-repeat;
    background-position: right;
    background-size: 800px;
    grid-template-columns: unset;
    position: relative;
    grid-row-gap: 0px;
  }
}

.description__button {
  text-decoration: none;
  width: max-content;
  height: max-content;
  display: grid;
  grid-template-columns: 5px 35px;
  grid-column-gap: 9px;
  align-content: center;
  padding: 9px 20px;
  border-radius: 37px;
  font-family: "Nexa Bold";
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  background-color: #253746;
}

.description__button__arrow {
  font-size: 10px;
}

.description__title {
  font-family: "Nexa Bold";
  font-size: 36px;
  font-weight: normal;
  line-height: 55px;
  color: #253746;

  @media (min-width: 768px) {
    margin-top: 89px;
    font-size: 56px;
    line-height: 68px;
  }
  @media (min-width: 1440px) {
    max-width: 440px;
    margin-top: 32px;
  }
}

.text-wrapper {
  display: block !important;
  margin-top: -32px;
  display: grid;
  grid-auto-rows: max-content;
  grid-row-gap: 27px;
  font-family: "Nexa Regular";
  font-size: 18px;
  line-height: 1.5;
  color: #4f616c;

  @media (min-width: 768px) {
    grid-column: 2;
    margin-top: 0;
  }
  @media (min-width: 1440px) {
    grid-column: unset;
    width: 440px;
    margin-top: 32px;
  }
}
