.description-home {
  width: 100%;
  display: grid;
  justify-content: center;
  background-image: url(../../../assets/images/Home/officebg.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 42%;

  @media (-webkit-min-device-pixel-ratio: 2) {
    background-image: url(../../../assets/images/Home/officebg@2x.webp);
  }
  @media (-webkit-min-device-pixel-ratio: 3) {
    background-image: url(../../../assets/images/Home/officebg@3x.webp);
  }
  @media (min-width: 768px) {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100%;
  }
  @media (min-width: 1440px) {
    height: 560px;
    background-image: none;
  }
}

.description-home__container {
  max-width: 264px;
  margin-left: 17px;
  padding-top: 71px;
  padding-bottom: 82px;
  display: grid;
  grid-auto-rows: max-content;
  grid-row-gap: 16px;

  @media (min-width: 768px) {
    max-width: unset;
    width: 768px;
    grid-template-columns: 303px 415px;
    margin: 0;
  }
  @media (min-width: 1440px) {
    width: 1392px;
    padding-left: 125px;
    padding-bottom: 24px;
    background-repeat: no-repeat;
    background-position: right;
    background-size: 800px;
    grid-template-columns: unset;
    background-image: url(../../../assets/images/Home/office.webp);
    @media (-webkit-min-device-pixel-ratio: 2) {
      background-image: url(../../../assets/images/Home/office@2x.webp);
    }
    @media (-webkit-min-device-pixel-ratio: 3) {
      background-image: url(../../../assets/images/Home/office@3x.webp);
    }
  }
}

.description-home__button {
  width: max-content;
  margin-top: 17px;
  padding: 23px 48px;
  border-radius: 37px;
  text-decoration: none;
  font-family: "Nexa Bold";
  font-size: 14px;
  line-height: 18px;
  color: #253746;
  background-color: #ffb81c;
  cursor: pointer;
  @media (min-width: 768px) {
    grid-column: 2;
    margin-top: 47px;
  }
  @media (min-width: 1440px) {
    grid-column: 1;
  }
}

.description-home__title {
  font-family: "Nexa Bold";
  font-size: 56px;
  font-weight: normal;
  line-height: 68px;
  color: #253746;

  @media (min-width: 768px) {
    grid-column: 2;
  }
  @media (min-width: 1440px) {
    max-width: 440px;
    grid-column: 1;
  }
}

.description-home__text-wrapper {
  display: grid;
  grid-auto-rows: max-content;
  grid-row-gap: 27px;
  font-family: "Nexa Bold";
  font-size: 24px;
  line-height: 1.25;
  color: #4f616c;

  @media (min-width: 768px) {
    grid-column: 2;
  }
  @media (min-width: 1440px) {
    grid-column: unset;
    width: 415px;
  }
}
