
.qualifications {
  width: 100%;
  display: grid;
  justify-content: center;
  background-color: #f0f3f6;
}

.qualifications__container {
  width: 312px;
  padding-top: 51px;
  padding-bottom: 61px;
  display: grid;
  grid-row-gap: 21px;
  @media (min-width: 768px) {
    width: 640px;
  }
  @media (min-width: 1440px) {
    width: 1210px;
    padding-top: 50px;
    padding-left: 35px;
    grid-row-gap: 20px;
  }
}

.qualifications__title {
  width: 225px;
  font-family: "Nexa Regular";
  font-size: 36px;
  font-weight: normal;
  line-height: 55px;
  color: #253746;
  @media (min-width: 768px) {
    width: 100%;
  }
}

.qualifications__list {
  display: grid;
  grid-row-gap: 21px;
  font-weight: bold;
  @media (min-width: 768px) {
    margin-left: 36px;
    grid-row-gap: 27px;
  }
  @media (min-width: 1440px) {
    margin-left: 53px;
  }
}

.qualifications__list-item__point {
  width: 4px;
  height: 4px;
  margin-top: 9px;
  border-radius: 50%;
  background-color: #40c1ac;
  @media (min-width: 768px) {
    width: 5px;
    height: 5px;
  }
}

.qualifications__list-item {
  display: grid;
  grid-template-columns: 6px 297px;
  grid-column-gap: 10px;
  font-family: "Nexa Light";
  font-size: 14px;
  line-height: 1.5;
  color: #253746;
  @media (min-width: 768px) {
    font-size: 18px;
    grid-template-columns: 9px 552px;
  }
  @media (min-width: 1440px) {
    grid-template-columns: 9px 1144px;
    grid-column-gap: 8px;
  }
}

.qualifications__subtitle {
  margin-top: 18px;
  font-family: "Nexa Light";
  font-size: 24px;
  font-weight: normal;
  line-height: 1.5;
  color: #253746;
  @media (min-width: 768px) {
    margin-top: 33px;
  }
}

.qualifications__advantage-list {
  display: grid;
  grid-row-gap: 21px;
  margin-top: 4px;
  @media (min-width: 768px) {
    grid-row-gap: 27px;
    margin-top: 0px;
    margin-left: 36px;
  }
  @media (min-width: 1440px) {
    margin-top: 3px;
    margin-left: 53px;
  }
}