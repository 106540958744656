
.abilities {
  width: 100%;
  display: grid;
  justify-content: center;
  background-color: #fff;
  overflow: hidden;
}

.abilities__container {
  padding-top: 51px;
  padding-bottom: 64px;
  display: grid;
  grid-row-gap: 26px;
}

.abilities__title {
  width: 312px;
  margin: auto;
  font-family: "Nexa Regular";
  font-size: 36px;
  font-weight: normal;
  line-height: 55px;
  color: #253746;
  @media (min-width: 768px) {
    margin-left: -8px;
  }
  @media (min-width: 1440px) {
    margin: 0;
  }
}

.abilities__circles-wrapper {
  margin-left: -87px;
  @media (min-width: 768px) {
    margin-left: 0;
    margin-top: 33px;
  }
}

.abilities__circles {
  width: 540px;
  justify-self: center;
  justify-content: end;
  display: grid;
  grid-template-rows: 75px 87px 118px 119px 200px;
  @media (min-width: 768px) {
    width: 100%;
    grid-template-rows: unset;
    grid-template-columns: 131px 32px 187px 74px 200px;
    justify-content: center;
  }
  @media (min-width: 1440px) {
    grid-template-columns: 185px 235px 285px 235px 200px;
    align-items: center;
  }
}

.abilities__circle {
  border-radius: 50%;
  font-family: "Nexa Regular";
  font-size: 18px;
  line-height: 1.5;
  color: #fff;
  display: grid;
  justify-items: center;
  align-items: center;
}

.abilities__circle--gray {
  width: 200px;
  height: 200px;
  margin: 0 151px 0 24px;
  padding: 72px 35px 77px;
  background-color: #515f6b;
  @media (min-width: 768px) {
    margin: 0;
    margin-top: 146px;
  }
  @media (min-width: 1440px) {
    margin: 0;
  }
}

.abilities__circle--yellow {
  width: 250px;
  height: 250px;
  margin: 0 0 0 164px;
  padding: 82px 42px 89px 51px;
  background-color: #ffb81c;
  color: #253746;
  @media (min-width: 768px) {
    margin: 0;
  }
  @media (min-width: 1440px) {
    margin: 0;
  }
}

.abilities__circle--red {
  width: 300px;
  height: 300px;
  margin-left: -63px;
  padding: 81px 73px 87px 73px;
  background-color: #f0525f;
  @media (min-width: 768px) {
    margin: 0;
    margin-top: 180px;
  }
  @media (min-width: 1440px) {
    margin: 0;
  }
}

.abilities__circle--black {
  width: 250px;
  height: 250px;
  margin: 0 0 0 164px;
  padding: 55px 52px 62px 47px;
  background-color: #253746;
  @media (min-width: 768px) {
    margin: 0;
    margin-top: 15px;
  }
  @media (min-width: 1440px) {
    margin: 0;
  }
}

.abilities__circle--turquoise {
  width: 200px;
  height: 200px;
  margin: 0 168px 0 7px;
  padding: 58px 24px;
  background-color: #65cebd;
  color: #253746;
  @media (min-width: 768px) {
    margin: 283px 0 62px 43px;
    margin: 0;
    margin-top: 219px;
  }
  @media (min-width: 1440px) {
    margin: 0;
  }
}