.first-things {
  width: 100%;
  display: grid;
  justify-content: center;
  background-color: #f0f3f6;
}

.first-things__container {
  max-width: 327px;
  padding-top: 52px;
  padding-bottom: 64px;
  display: grid;
  grid-row-gap: 25px;
  @media (min-width: 768px) {
    max-width: unset;
    width: 640px;
    grid-row-gap: 27px;
  }
  @media (min-width: 1440px) {
    width: 1140px;
  }
}

.first-things__title {
  font-family: "Nexa Regular";
  font-size: 36px;
  font-weight: normal;
  line-height: 55px;
  color: #253746;
  margin-left: 8px;
  @media (min-width: 768px) {
    margin-left: 0;
  }
}

.first-things__list {
  display: grid;
  grid-row-gap: 30px;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 270px);
    grid-column-gap: 30px;
    justify-self: center;
    grid-row-gap: 22px;
  }
  @media (min-width: 1440px) {
    grid-template-columns: repeat(3, max-content);
    grid-column-gap: 0;
    margin-left: 50px;
    grid-auto-rows: 178px;
  }
}

.first-things__list--four-columns {
  @media (min-width: 1440px) {
    grid-template-columns: repeat(4, max-content);
    margin-left: -15px;
  }
}

.first-things__list-item {
  display: grid;
  grid-template-columns: 25px 300px;
  font-family: "Nexa Light";
  font-size: 14px;
  line-height: 1.5;
  color: #253746;
  @media (min-width: 768px) {
    grid-template-columns: 50px 250px;
    font-size: 18px;
  }
}

.first-things__list-item__circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #d9f3ee;
  @media (min-width: 768px) {
    width: 100px;
    height: 100px;
  }
}

.first-things__list-item__text {
  margin-top: 21px;
  @media (min-width: 768px) {
    margin-top: 43px;
  }
}
