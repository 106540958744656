.footer {
  width: 100%;
  min-width: 375px;
  height: 100px;
  margin-top: 32px;
  padding: 28px 24px;
  display: grid;
  grid-template-columns: 168px max-content;
  justify-content: space-between;
  background-color: #253746;
  @media (min-width: 768px) {
    margin-top: 64px;
    grid-template-columns: 335px max-content;
    align-content: center;
  }
  @media (min-width: 1440px) {
    grid-column-gap: 1042px;
    justify-content: center;
  }
}

.footer__copyright {
  font-family: "Nexa Light";
  font-size: 12px;
  line-height: 1.83;
  text-align: left;
  color: #515f6b;
}

.footer_icon-up {
  width: 35px;
  height: 28px;
  align-self: center;
  cursor: pointer;
  display: block;
  padding: 10px;
}
